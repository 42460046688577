// src/FileUpload.tsx
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, LinearProgress, List, ListItem, ListItemText, Grid, Paper, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './FileUpload.css';

interface UploadProgress {
  [key: string]: number;
}

const FileUpload1: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>({});
  const [filePreviews, setFilePreviews] = useState<{ [key: string]: string }>({});

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await axios.post('https://upload.doaepestforecast.com/upload-sweetcorn/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress((prevProgress) => ({ ...prevProgress, [file.name]: progress }));
          }
        },
      });
      console.log('Upload response:', response.data);
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      setFiles((prevFiles) => [...prevFiles, file]);
      handleUpload(file);
      const preview = URL.createObjectURL(file);
      setFilePreviews((prevPreviews) => ({ ...prevPreviews, [file.name]: preview }));
    });
  }, []);

  useEffect(() => {
    return () => {
      // Clean up file preview URLs
      Object.values(filePreviews).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [filePreviews]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  return (
    <Grid container spacing={2} justifyContent="center" className="file-upload-container">
      <Grid item xs={12} className="header-container">
        <h1 className="home-grid-title">
          <img src="logo192.png" alt="logo" className="logo" />
          <span className="lineoa">DOAE</span> LINEOA
          <p className="subtitle">อัปโหลดรูป ข้าวโพด</p>
        </h1>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <UploadIcon  sx={{ fontSize: 80, marginBottom: 2 }} className="dropzone-icon" />
          <Typography variant="body1">
            {' '}
            <Typography variant="body1" component="span" color="primary" sx={{ cursor: 'pointer' }}>
              เลือกจากคลังรูปภาพ
            </Typography>
          </Typography>
          <Typography variant="body2" className="upload-info">ขนาดข้อมูลไม่เกิน 50mb</Typography>
        </Paper>
      </Grid>
      {files.length > 0 && (
        <Grid item xs={12} md={8}>
          <Paper className="file-list">
            <Typography variant="h6" className="center-text">ข้อมูล อัปโหลด</Typography>
            <List>
              {files.map((file) => (
                <ListItem key={file.name} className="file-item">
                  <img src={filePreviews[file.name]} alt={file.name} className="uploaded-image" />
                  <ListItemText primary={file.name} secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`} />
                  {uploadProgress[file.name] && uploadProgress[file.name] < 100 ? (
                    <Box className="progress-bar">
                      <LinearProgress variant="determinate" value={uploadProgress[file.name]} />
                    </Box>
                  ) : (
                    <Typography variant="body2" className="upload-complete">
                      อัปโหลดรูป เรียบร้อย
                    </Typography>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} md={8} className="back-button-container">
        <Button variant="contained" color="primary" component={Link} to="/">
          กลับเลือกชนิดพืช อัปโหลดรูป
        </Button>
      </Grid>
    </Grid>
  );
};

export default FileUpload1;
