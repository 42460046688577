// src/HomeGrid.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import './HomeGrid.css';

const HomeGrid: React.FC = () => {
  return (
    <div className="home-grid">
      <h1 className="home-grid-title">
      <img src="logo192.png" alt="logo" className="logo" />
        <span className="lineoa">DOAE</span> LINEOA
        <p className="subtitle">เลือกชนิดพืช อัปโหลดรูป</p>
      </h1>

      <div className="grid-container">
        <Link to="/upload1" className="grid-item">
          <img src="doae/coconut-icon.png" alt="coconut" />
          <div className="overlay">
            <p>มะพร้าว</p>
          </div>
        </Link>
        <Link to="/upload2" className="grid-item">
          <img src="doae/durian-icon.png" alt="durian" />
          <div className="overlay">
            <p>ทุเรียน</p>
          </div>
        </Link>
        <Link to="/upload3" className="grid-item">
          <img src="doae/rice-icon.png" alt="rice" />
          <div className="overlay">
            <p>ข้าว</p>
          </div>
        </Link>
        <Link to="/upload4" className="grid-item">
          <img src="doae/cassava-icon.png" alt="cassava" />
          <div className="overlay">
            <p>มันสำปะหลัง</p>
          </div>
        </Link>
        <Link to="/upload5" className="grid-item">
          <img src="doae/sugar-cane-icon.png" alt="sugar-cane" />
          <div className="overlay">
            <p>อ้อย</p>
          </div>
        </Link>
        <Link to="/upload6" className="grid-item">
          <img src="doae/sweetcorn-icon.png" alt="sweetcorn" />
          <div className="overlay">
            <p>ข้าวโพด</p>
          </div>
        </Link>
        <Link to="/upload7" className="grid-item">
          <img src="doae/palm-icon.png" alt="palm" />
          <div className="overlay">
            <p>ปาล์ม</p>
          </div>
        </Link>
        <Link to="/upload8" className="grid-item">
          <img src="doae/mangosteen-icon.png" alt="mangosteen" />
          <div className="overlay">
            <p>มังคุด</p>
          </div>
        </Link>
        <Link to="/upload9" className="grid-item">
          <img src="doae/vegetable-icon.png" alt="vegetable" />
          <div className="overlay">
            <p>ผัก</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HomeGrid;
