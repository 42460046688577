// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeGrid from './pages/HomeGrid';
import FileUpload1 from './upload/FileUpload1';
import FileUpload2 from './upload/FileUpload2';
import FileUpload3 from './upload/FileUpload3';
import FileUpload4 from './upload/FileUpload4';
import FileUpload5 from './upload/FileUpload5';
import FileUpload6 from './upload/FileUpload6';
import FileUpload7 from './upload/FileUpload7';
import FileUpload8 from './upload/FileUpload8';
import FileUpload9 from './upload/FileUpload9';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeGrid />} />
          <Route path="/upload1" element={<FileUpload1 />} />
          <Route path="/upload2" element={<FileUpload2 />} />
          <Route path="/upload3" element={<FileUpload3 />} />
          <Route path="/upload4" element={<FileUpload4 />} />
          <Route path="/upload5" element={<FileUpload5 />} />
          <Route path="/upload6" element={<FileUpload6 />} />
          <Route path="/upload7" element={<FileUpload7 />} />
          <Route path="/upload8" element={<FileUpload8 />} />
          <Route path="/upload9" element={<FileUpload9 />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
